import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import { Context as OffersContext } from '../../context/OffersContext';
import { Context as ProofPopupsContext } from '../../context/ProofPopupsContext';
import { Context as UserContext } from '../../context/UserContext';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import ImageInput from '../../components/ImageInput/ImageInput';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 750,
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	topButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(4),
	},
	modalButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(3),
	},

	textField: {
		marginBottom: 0,
	},
}));

export default function ProofPopups({ onRender }) {
	const classes = useStyles();

	// STATE
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [proofPopupToDelete, setProofPopupToDelete] = useState(null);

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { proofPopups, error, loading },
		loadProofPopups,
		createProofPopup,
		updateProofPopup,
		deleteProofPopup,
	} = useContext(ProofPopupsContext);

	const {
		state: { offers },
		loadOffers,
	} = useContext(OffersContext);

	// EFFECTS
	useEffect(() => {
		if (user && !proofPopups.length) {
			loadProofPopups();
			loadOffers();
		}

		onRender('Proof Popups');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	if (error) {
		return <Error error={error} />;
	}

	if (loading) {
		return <Loading />;
	}

	const Row = ({ row }) => {
		const [state, setState] = useState(row);

		function handleChange(e) {
			setState({
				...state,
				[e.target.name]: e.target.value,
			});
		}

		function handleSave() {
			updateProofPopup(row._id, state);
		}

		function handleDelete(id) {
			setProofPopupToDelete(id);
			setDeleteModalOpen(true);
		}

		return (
			<TableRow key={row._id} className={classes.row}>
				<TableCell>
					<FormControl fullWidth>
						<InputLabel shrink>Offer</InputLabel>
						<Select
							value={state.offer}
							onChange={(e) =>
								updateProofPopup(row._id, {
									...state,
									offer: e.target.value,
								})
							}
						>
							{offers.map((item, index) => (
								<MenuItem key={index} value={item._id}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</TableCell>
				<TableCell size='small'>
					<TextField
						fullWidth
						label='Name'
						name='name'
						value={state.name}
						onChange={handleChange}
						onBlur={() => handleSave()}
					/>
				</TableCell>
				<TableCell>
					<TextField
						fullWidth
						label='City'
						name='city'
						value={state.city}
						onChange={handleChange}
						onBlur={() => handleSave()}
					/>
				</TableCell>
				<TableCell>
					<TextField
						fullWidth
						label='Product'
						name='product'
						value={state.product}
						onChange={handleChange}
						onBlur={() => handleSave()}
					/>
				</TableCell>
				<TableCell>
					<TextField
						fullWidth
						label='Date'
						name='date'
						value={state.date}
						onChange={handleChange}
						onBlur={() => handleSave()}
					/>
				</TableCell>
				<TableCell>
					<TextField
						fullWidth
						label='Company'
						name='company'
						value={state.company}
						onChange={handleChange}
						onBlur={() => handleSave()}
					/>
				</TableCell>
				<TableCell>
					<ImageInput
						fullWidth
						label='Image'
						name='image'
						value={state.image}
						onChange={handleChange}
						onBlur={() => handleSave()}
						className={classes.textField}
					/>
				</TableCell>
				<TableCell align='right'>
					<IconButton
						color='secondary'
						aria-label='Delete Proof Popup'
						component='span'
						onClick={() => handleDelete(row._id)}
					>
						<DeleteIcon />
					</IconButton>
				</TableCell>
			</TableRow>
		);
	};

	function renderDeleteModal() {
		return (
			<Modal open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
				<Box>
					<Typography variant='h6' gutterBottom>
						Are you sure you want to delete this Proof Popup?
					</Typography>
				</Box>
				<Box className={classes.modalButtons}>
					<Button
						variant='contained'
						color='primary'
						style={{ marginRight: '10px' }}
						onClick={async () => {
							setDeleteModalOpen(false);
						}}
					>
						No
					</Button>
					<Button
						variant='contained'
						color='secondary'
						onClick={async () => {
							await deleteProofPopup(proofPopupToDelete);
							setDeleteModalOpen(false);
						}}
					>
						Yes
					</Button>
				</Box>
			</Modal>
		);
	}

	return (
		<>
			{renderDeleteModal()}
			<Container className={classes.container}>
				<Box className={classes.topButtons}>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						onClick={() => createProofPopup()}
					>
						Add
					</Button>
				</Box>
				<TableContainer component={Paper} className={classes.paper}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Offer</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>City</TableCell>
								<TableCell>Product</TableCell>
								<TableCell>Date</TableCell>
								<TableCell>Company</TableCell>
								<TableCell>Image</TableCell>
								<TableCell align='right'>Delete</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{proofPopups.map((item, index) => (
								<Row row={item} key={index} />
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
		</>
	);
}
