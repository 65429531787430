import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import { Context as OffersContext } from '../../context/OffersContext';
import { Context as PromosContext } from '../../context/PromosContext';
import { Context as PromoContext } from '../../context/PromoContext';
import { Context as UserContext } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 750,
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	title: {
		marginTop: theme.spacing(4),
	},
	topButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(4),
	},
	modalButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(3),
	},
}));

export default function PromosPage({ onRender }) {
	const classes = useStyles();
	const history = useHistory();

	// STATE
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [promoToDelete, setPromoToDelete] = useState(null);

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { promos, loading: promosLoading, error: promosError },
		loadPromos,
	} = useContext(PromosContext);

	const {
		state: { offers, loading: offersLoading, error: offersError },
		loadOffers,
	} = useContext(OffersContext);

	const {
		state: { promo, error: promoError },
		createPromo,
		deletePromo,
	} = useContext(PromoContext);

	// EFFECTS
	useEffect(() => {
		if (user || promo) {
			loadPromos();
			loadOffers();
		}

		onRender('Promos');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, promo]);

	function handleDeletePromo(id) {
		setDeleteModalOpen(true);
		setPromoToDelete(id);
	}

	async function handleCreatePromo() {
		const newPromo = await createPromo();
		history.push(`/promos/${newPromo._id}`);
	}

	function renderOfferName(id) {
		const offer = offers.find((item) => item._id === id);
		return offer ? offer.name : id;
	}

	if (promosError || promoError) {
		return <Error error={promosError || promoError || offersError} />;
	}

	if (promosLoading || offersLoading) {
		return <Loading />;
	}

	return (
		<>
			<Modal open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
				<Box>
					<Typography variant='h6' gutterBottom>
						Are you sure you want to delete this promo?
					</Typography>
				</Box>
				<Box className={classes.modalButtons}>
					<Button
						variant='contained'
						color='primary'
						style={{ marginRight: '10px' }}
						onClick={async () => {
							setDeleteModalOpen(false);
						}}
					>
						No
					</Button>
					<Button
						variant='contained'
						color='secondary'
						onClick={async () => {
							await deletePromo(promoToDelete);
							setDeleteModalOpen(false);
						}}
					>
						Yes
					</Button>
				</Box>
			</Modal>
			<Container className={classes.container}>
				<Box className={classes.topButtons}>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						onClick={() => handleCreatePromo()}
					>
						Add
					</Button>
				</Box>
				<TableContainer component={Paper} className={classes.paper}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>id</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Description</TableCell>
								<TableCell>Offer</TableCell>
								<TableCell align='right'>Delete</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{promos.map((row) => (
								<TableRow key={row._id} hover>
									<TableCell component='th' scope='row'>
										<Link
											component='button'
											variant='body2'
											onClick={() => {
												history.push(`/promos/${row._id}`);
											}}
										>
											{row._id}
										</Link>
									</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.description}</TableCell>
									<TableCell>{renderOfferName(row.offerId)}</TableCell>
									<TableCell align='right'>
										<IconButton
											color='secondary'
											aria-label='Delete promo'
											component='span'
											onClick={() => handleDeletePromo(row._id)}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
		</>
	);
}
