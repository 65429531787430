import createDataContext from './createDataContext';
import { api } from '../api/api';

const bannersReducer = (state, action) => {
	switch (action.type) {
		case 'REQUEST':
			return {
				...state,
				loading: true,
				error: false,
			};
		case 'SUCCESS':
			return {
				...state,
				loading: false,
				banners: action.payload,
			};
		case 'ERROR':
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

const loadBanners = (dispatch) => async () => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.get('/banners', {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to load banners',
		});
	}
};

const createBanner = (dispatch) => async (data) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.post(`/banners`, data, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});

		return response.data;
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to create banner.',
		});
	}
};

const updateBanner = (dispatch) => async (id, data) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.patch(`/banners/${id}`, data, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to update banner.',
		});
	}
};

const deleteBanner = (dispatch) => async (id) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.delete(`/banners/${id}`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to delete banner.',
		});
	}
};

export const { Context, Provider } = createDataContext(
	bannersReducer,
	{
		loadBanners,
		createBanner,
		updateBanner,
		deleteBanner,
	},
	{
		banners: [],
		error: '',
		loading: false,
	}
);
