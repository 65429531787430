import createDataContext from './createDataContext';
import { api } from '../api/api';

const crossSellsReducer = (state, action) => {
	switch (action.type) {
		case 'REQUEST':
			return {
				...state,
				loading: true,
				error: false,
			};
		case 'SUCCESS':
			return {
				...state,
				loading: false,
				crossSells: action.payload,
			};
		case 'ERROR':
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

const loadCrossSells = (dispatch) => async () => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.get('/crossSells', {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to load cross-sells',
		});
	}
};

export const { Context, Provider } = createDataContext(
	crossSellsReducer,
	{
		loadCrossSells,
	},
	{
		crossSells: [],
		error: '',
		loading: false,
	}
);
