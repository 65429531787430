import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	cardsContainer: {
		display: 'flex',
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
		overflowX: 'scroll',
		flexWrap: 'wrap',
	},
	productCard: {
		width: `calc(25% - ${theme.spacing(2)}px)`,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		margin: theme.spacing(1),
		padding: 0,
		cursor: 'pointer',
	},
	productCardPopular: {
		color: 'white',
		textAlign: 'center',
		backgroundColor: '#4050b5',
	},
	productCardContent: {
		flex: '1',
	},
	syncButton: {
		width: '100%',
		marginBottom: theme.spacing(3),
	},
}));

export default function ProductsList({ offer, onSyncClick, onProductClick }) {
	const classes = useStyles();

	function handleProductClick(productId) {
		if (offer.mostPopularProductIds.includes(productId)) {
			onProductClick(offer.mostPopularProductIds.filter((item) => item !== productId));
		} else {
			onProductClick([...offer.mostPopularProductIds, productId]);
		}
	}

	return (
		<>
			<Typography variant='subtitle2' gutterBottom>
				Products in offer
			</Typography>
			<Box className={classes.cardsContainer}>
				{offer.productsData.map((item, index) => (
					<Card
						key={index}
						variant='outlined'
						className={classes.productCard}
						onClick={() => handleProductClick(item.id)}
					>
						{offer.mostPopularProductIds.includes(item.id) && (
							<div className={classes.productCardPopular}>Most Popular</div>
						)}
						<CardContent className={classes.productCardContent}>
							<Typography className={classes.title} color='textSecondary' variant='subtitle2' gutterBottom>
								id - {item.id}
							</Typography>
							<Typography variant='body2'>{item.name}</Typography>
							<Typography style={{ fontWeight: 'bold' }} variant='caption'>
								Price - ${item.price}
							</Typography>
							<Typography variant='caption' style={{ display: 'block' }}>
								Shipping id: {item.shippingId} (${item.shippingPrice})
							</Typography>
							<Typography variant='caption' style={{ display: 'block' }}>
								MSRP - ${item.oldPrice}
							</Typography>
							{item.soldOut && (
								<Typography variant='caption' style={{ display: 'block' }}>
									<b style={{ color: 'red' }}>SOLD OUT</b>
								</Typography>
							)}
						</CardContent>
					</Card>
				))}
			</Box>
			<Button variant='contained' color='primary' className={classes.syncButton} onClick={onSyncClick}>
				Sync products
			</Button>
		</>
	);
}
