import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ImageInput from '../../components/ImageInput/ImageInput';
import Error from '../../components/Error/Error';
import { Context as UserContext } from '../../context/UserContext';
import { Context as FreeProductContext } from '../../context/FreeProductContext';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	paper: {
		width: '100%',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	container: {
		marginTop: theme.spacing(3),
	},
	progress: {
		marginTop: theme.spacing(2),
	},
	textField: {
		marginBottom: theme.spacing(3),
	},
	products: {
		display: 'flex',
	},
	productsIcon: {
		alignSelf: 'center',
		marginLeft: theme.spacing(1),
	},
}));

export default function FreeProductPage({ onRender }) {
	// VARS
	const classes = useStyles();
	const { id } = useParams();

	// STATE
	const [stickyId, setStickyId] = useState('');
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [stock, setStock] = useState('');
	const [forModal, setForModal] = useState(false);
	const [imageSrc, setImageSrc] = useState('');
	const [active, setActive] = useState(true);

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { freeProduct, loading: freeProductLoading, error: freeProductError },
		loadFreeProduct,
		updateFreeProduct,
	} = useContext(FreeProductContext);

	// EFFECTS
	useEffect(() => {
		if (user) {
			loadFreeProduct(id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (freeProduct) {
			onRender(`Free product - ${freeProduct.name}`);
			// Set values after freeProduct is loaded
			setName(freeProduct.name);
			setDescription(freeProduct.description);
			setStickyId(freeProduct.stickyId);
			setStock(freeProduct.stock);
			setImageSrc(freeProduct.imageSrc);
			setForModal(freeProduct.forModal);
			setActive(freeProduct.active);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [freeProduct]);

	function renderOrderSelect(order) {
		function handleChange(e) {
			handleFreeProductUpdate({
				order: e.target.value,
			});
		}

		function buildMenuItems() {
			const result = [];

			for (let index = 1; index <= 20; index++) {
				result.push(
					<MenuItem key={index} value={index}>
						{index}
					</MenuItem>
				);
			}

			return result;
		}

		return (
			<FormControl fullWidth>
				<InputLabel id='orderSelect'>Order</InputLabel>
				<Select labelId='orderSelect' value={order} onChange={handleChange} label='Order' size='small'>
					{buildMenuItems()}
				</Select>
			</FormControl>
		);
	}

	if (freeProductError) {
		return <Error error={freeProductError} />;
	}

	if (!freeProduct) {
		return null;
	}

	function handleFreeProductUpdate(update) {
		updateFreeProduct(id, update);
	}

	return (
		<Container maxWidth='lg' className={classes.container}>
			{freeProductLoading && <LinearProgress className={classes.progress} />}
			<Paper className={classes.paper}>
				<TextField
					label='Id'
					value={freeProduct._id}
					fullWidth
					InputProps={{
						readOnly: true,
					}}
					className={classes.textField}
				/>
				<TextField
					label='Sticky Id'
					value={stickyId}
					helperText='Note: Leave it empty for sold out products'
					onChange={(e) => setStickyId(e.target.value)}
					onBlur={() =>
						handleFreeProductUpdate({
							stickyId,
						})
					}
					fullWidth
					className={classes.textField}
				/>
				<TextField
					label='Name'
					value={name}
					onChange={(e) => setName(e.target.value)}
					onBlur={() =>
						handleFreeProductUpdate({
							name,
						})
					}
					fullWidth
					className={classes.textField}
				/>
				<TextField
					label='Description'
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					onBlur={() =>
						handleFreeProductUpdate({
							description,
						})
					}
					fullWidth
					className={classes.textField}
				/>
				<TextField
					label='Stock'
					value={stock}
					onChange={(e) => setStock(e.target.value)}
					onBlur={() =>
						handleFreeProductUpdate({
							stock,
						})
					}
					fullWidth
					className={classes.textField}
				/>
				<FormControlLabel
					style={{
						marginBottom: '24px',
					}}
					control={
						<Checkbox
							checked={forModal}
							onChange={(e) =>
								handleFreeProductUpdate({
									forModal: e.target.checked,
								})
							}
							name='For modal'
							color='primary'
						/>
					}
					label='For modal'
				/>
				<FormControlLabel
					style={{
						marginBottom: '24px',
					}}
					control={
						<Checkbox
							checked={active}
							onChange={(e) =>
								handleFreeProductUpdate({
									active: e.target.checked,
								})
							}
							name='Active'
							color='primary'
						/>
					}
					label='Active'
				/>
				<ImageInput
					label='Image source url'
					value={imageSrc}
					onChange={(e) => setImageSrc(e.target.value)}
					onBlur={(e) =>
						handleFreeProductUpdate({
							imageSrc,
						})
					}
				/>
				{renderOrderSelect(freeProduct.order)}
			</Paper>
		</Container>
	);
}

FreeProductPage.propTypes = {
	onRender: PropTypes.func,
};
