/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import { Context as OfferContext } from '../../context/OfferContext';
import { Context as UserContext } from '../../context/UserContext';
import { Context as SpecialOffersContext } from '../../context/SpecialOffersContext';
import { Context as FreeProductsContext } from '../../context/FreeProductsContext';
import { Context as CrossSellsContext } from '../../context/CrossSellsContext';
import { Context as ReviewsContext } from '../../context/ReviewsContext';
import ImageInput from '../../components/ImageInput/ImageInput';
import GalleryItem from '../../components/GalleryItem/GalleryItem';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import ChipsList from '../../components/ChipsList/ChipsList';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import ProductsList from '../../components/ProductsList/ProductsList';
import { Button, FormControlLabel, Paper, Switch, Tab, Tabs } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	paper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(3),
	},
	pageActionButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: theme.spacing(2),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	container: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	progress: {
		marginTop: theme.spacing(2),
	},
	textField: {
		marginBottom: theme.spacing(3),
	},
	products: {
		display: 'flex',
		alignItems: 'flex-start',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
		'& $textField': {
			marginRight: theme.spacing(2),
			marginBottom: 0,
			[theme.breakpoints.down('xs')]: {
				marginRight: 0,
			},
		},
	},
	productRow: {
		display: 'flex',
		marginBottom: theme.spacing(3),
		'&:last-child': {
			marginBottom: 0,
		},
	},
	iconsGroup: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	productsIcon: {
		alignSelf: 'center',
		marginLeft: theme.spacing(1),
	},
	specialOffers: {
		marginBottom: theme.spacing(3),
	},
	bannerDatePicker: {
		marginBottom: theme.spacing(2),
	},
	freeProducts: {
		marginBottom: theme.spacing(3),
	},
	crossSells: {
		marginBottom: theme.spacing(3),
	},
	switch: {
		marginTop: theme.spacing(2),
	},
	reviews: {
		marginBottom: theme.spacing(3),
	},
	fbPixelPair: {
		display: 'flex',
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
	fbPixelId: {
		flex: 1,
		marginRight: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			marginBottom: theme.spacing(2),
		},
	},
	fbPixelToken: {
		flex: 3,
	},
	fbPixelIcons: {
		display: 'flex',
		justifyContent: 'center',
	},
	fbPixelIcon: {
		alignSelf: 'center',
		marginLeft: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			marginBottom: theme.spacing(2),
		},
	},
}));

export default function OfferPage({ onRender }) {
	// VARS
	const classes = useStyles();
	const { id } = useParams();
	const [value, setValue] = useState(0);
	const history = useHistory();

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { specialOffers, loading: specialOffersLoading, error: specialOffersError },
		loadSpecialOffers,
	} = useContext(SpecialOffersContext);

	const {
		state: { reviews, loading: reviewsLoading, error: reviewsError },
		loadReviews,
	} = useContext(ReviewsContext);

	const {
		state: { freeProducts, loading: freeProductsLoading, error: freeProductsError },
		loadFreeProducts,
	} = useContext(FreeProductsContext);

	const {
		state: { crossSells, loading: crossSellsLoading, error: crossSellsError },
		loadCrossSells,
	} = useContext(CrossSellsContext);

	const {
		state: { offer, loading: offerLoading, error: offerError },
		loadOffer,
		updateOffer,
		createOffer,
	} = useContext(OfferContext);

	useEffect(() => {
		if (user) {
			loadOffer(id);
			loadSpecialOffers();
			loadFreeProducts();
			loadReviews();
			loadCrossSells();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		onRender(`Offer - ${offer?.name}`);
	}, [offer]);

	if (offerError || specialOffersError || freeProductsError || reviewsError || crossSellsError) {
		return (
			<Error
				error={offerError || specialOffersError || freeProductsError || reviewsError || crossSellsError}
			/>
		);
	}

	if (specialOffersLoading || freeProductsLoading || reviewsLoading || crossSellsLoading) {
		return <Loading />;
	}

	if (!offer) {
		return null;
	}

	function handleOfferUpdate(update) {
		updateOffer(id, update);
	}

	const PreloaderSettings = () => {
		const [preloader, setPreloader] = useState({
			discount: '',
			review: {
				author: '',
				text: '',
			},
		});

		useEffect(() => {
			setPreloader(offer.preloader);
		}, [offer.preloader]);

		function handleDiscountChange(value) {
			setPreloader({
				...preloader,
				discount: value,
			});
		}

		function handleReviewChange(field, value) {
			setPreloader({
				...preloader,
				review: {
					...preloader.review,
					[field]: value,
				},
			});
		}

		return (
			<>
				<Typography variant='subtitle2' gutterBottom>
					Preloader
				</Typography>
				<TextField
					label='Discount'
					helperText='Example: 50%'
					value={preloader.discount}
					onChange={(e) => handleDiscountChange(e.target.value)}
					onBlur={(e) =>
						handleOfferUpdate({
							preloader,
						})
					}
					fullWidth
					className={classes.textField}
				/>
				<TextField
					label='Review author'
					value={preloader.review.author}
					onChange={(e) => handleReviewChange('author', e.target.value)}
					onBlur={() =>
						handleOfferUpdate({
							preloader,
						})
					}
					fullWidth
					className={classes.textField}
				/>
				<TextField
					label='Review text'
					value={preloader.review.text}
					onChange={(e) => handleReviewChange('text', e.target.value)}
					onBlur={() =>
						handleOfferUpdate({
							preloader,
						})
					}
					fullWidth
					className={classes.textField}
				/>
			</>
		);
	};

	const CompanySettings = () => {
		const [company, setCompany] = useState({
			name: '',
			email: '',
			phone: '',
			address: '',
			website: '',
		});

		useEffect(() => {
			setCompany(offer.company);
		}, [offer]);

		function handleCompanyChange(field, value) {
			setCompany({
				...company,
				[field]: value,
			});
		}

		return (
			<>
				<Typography variant='subtitle2' gutterBottom>
					Company
				</Typography>
				<TextField
					label='Name'
					value={company.name}
					onChange={(e) => handleCompanyChange('name', e.target.value)}
					onBlur={() =>
						handleOfferUpdate({
							company,
						})
					}
					fullWidth
					className={classes.textField}
				/>
				<TextField
					label='Email'
					value={company.email}
					onChange={(e) => handleCompanyChange('email', e.target.value)}
					onBlur={() =>
						handleOfferUpdate({
							company,
						})
					}
					fullWidth
					className={classes.textField}
				/>
				<TextField
					label='Website'
					value={company.website}
					onChange={(e) => handleCompanyChange('website', e.target.value)}
					onBlur={() =>
						handleOfferUpdate({
							company,
						})
					}
					fullWidth
					className={classes.textField}
				/>
				<TextField
					label='Phone'
					value={company.phone}
					onChange={(e) => handleCompanyChange('phone', e.target.value)}
					onBlur={() =>
						handleOfferUpdate({
							company,
						})
					}
					fullWidth
					className={classes.textField}
				/>
				<TextField
					label='Address'
					value={company.address}
					onChange={(e) => handleCompanyChange('address', e.target.value)}
					onBlur={() =>
						handleOfferUpdate({
							company,
						})
					}
					fullWidth
					className={classes.textField}
				/>
			</>
		);
	};

	const FbPixels = () => {
		const [fbPixels, setFbPixels] = useState([]);

		useEffect(() => {
			setFbPixels(offer.fbPixels);
		}, [offer]);

		function handleUpdatePixel(e, index) {
			const newFbPixels = [...fbPixels];
			newFbPixels[index][e.target.name] = e.target.value;
			setFbPixels(newFbPixels);
		}

		function handleAddFbPixel() {
			handleOfferUpdate({
				fbPixels: [
					...fbPixels,
					{
						id: '',
						token: '',
					},
				],
			});
		}

		function handleDeleteFbPixel(id) {
			handleOfferUpdate({
				fbPixels: fbPixels.filter((item) => item.id !== id),
			});
		}

		if (!fbPixels.length) {
			return (
				<>
					<Typography variant='subtitle2' gutterBottom>
						Facebook pixels
					</Typography>
					<IconButton
						size='small'
						color='primary'
						aria-label='Add'
						component='span'
						onClick={() => handleAddFbPixel()}
						className={classes.fbPixelIcon}
					>
						<AddIcon />
					</IconButton>
				</>
			);
		}

		return (
			<>
				<Typography variant='subtitle2' gutterBottom>
					Facebook pixels
				</Typography>
				{fbPixels.map(({ id, token }, index) => (
					<Box className={classes.fbPixelPair} key={index}>
						<TextField
							name='id'
							label='Pixel id'
							value={id}
							className={classes.fbPixelId}
							onChange={(e) => handleUpdatePixel(e, index)}
							onBlur={() =>
								handleOfferUpdate({
									fbPixels,
								})
							}
						/>
						<TextField
							name='token'
							label='Access token'
							value={token}
							multiline
							maxRows={4}
							className={classes.fbPixelToken}
							onChange={(e) => handleUpdatePixel(e, index)}
							onBlur={() =>
								handleOfferUpdate({
									fbPixels,
								})
							}
						/>
						<Box className={classes.fbPixelIcons}>
							<IconButton
								color='secondary'
								aria-label='delete pixel'
								component='span'
								size='small'
								className={classes.fbPixelIcon}
								onClick={() => handleDeleteFbPixel(id)}
							>
								<DeleteIcon />
							</IconButton>

							<IconButton
								size='small'
								color='primary'
								aria-label='Add'
								component='span'
								onClick={() => handleAddFbPixel()}
								className={classes.fbPixelIcon}
							>
								<AddIcon />
							</IconButton>
						</Box>
					</Box>
				))}
			</>
		);
	};

	const TikTokPixels = () => {
		const [tikTokPixels, setTikTokPixels] = useState([]);

		useEffect(() => {
			setTikTokPixels(offer.tikTokPixels);
		}, [offer]);

		function handleUpdatePixel(e, index) {
			const newTikTokPixels = [...tikTokPixels];
			newTikTokPixels[index][e.target.name] = e.target.value;
			setTikTokPixels(newTikTokPixels);
		}

		function handleAddTikTokPixel() {
			handleOfferUpdate({
				tikTokPixels: [
					...tikTokPixels,
					{
						id: '',
						token: '',
					},
				],
			});
		}

		function handleDeleteTikTokPixel(id) {
			handleOfferUpdate({
				tikTokPixels: tikTokPixels.filter((item) => item.id !== id),
			});
		}

		if (!tikTokPixels.length) {
			return (
				<>
					<Typography variant='subtitle2' gutterBottom>
						TikTok pixels
					</Typography>
					<IconButton
						size='small'
						color='primary'
						aria-label='Add'
						component='span'
						onClick={() => handleAddTikTokPixel()}
						className={classes.fbPixelIcon}
					>
						<AddIcon />
					</IconButton>
				</>
			);
		}

		return (
			<>
				<Typography variant='subtitle2' gutterBottom>
					TikTok pixels
				</Typography>
				{tikTokPixels.map(({ id, token }, index) => (
					<Box className={classes.fbPixelPair} key={index}>
						<TextField
							name='id'
							label='Pixel id'
							value={id}
							className={classes.fbPixelId}
							onChange={(e) => handleUpdatePixel(e, index)}
							onBlur={() =>
								handleOfferUpdate({
									tikTokPixels,
								})
							}
						/>
						<TextField
							name='token'
							label='Access token'
							value={token}
							multiline
							maxRows={4}
							className={classes.fbPixelToken}
							onChange={(e) => handleUpdatePixel(e, index)}
							onBlur={() =>
								handleOfferUpdate({
									tikTokPixels,
								})
							}
						/>
						<Box className={classes.fbPixelIcons}>
							<IconButton
								color='secondary'
								aria-label='delete pixel'
								component='span'
								size='small'
								className={classes.fbPixelIcon}
								onClick={() => handleDeleteTikTokPixel(id)}
							>
								<DeleteIcon />
							</IconButton>

							<IconButton
								size='small'
								color='primary'
								aria-label='Add'
								component='span'
								onClick={() => handleAddTikTokPixel()}
								className={classes.fbPixelIcon}
							>
								<AddIcon />
							</IconButton>
						</Box>
					</Box>
				))}
			</>
		);
	};

	function renderSpecialOffers() {
		function handleSpecialOffersChange(values) {
			const newValues = values.map((item) => item.id);

			handleOfferUpdate({
				specialOfferIds: newValues,
			});
		}

		const options = specialOffers.map((item) => ({
			id: item._id,
			name: `${item.name} [${item.template.name}]`,
		}));

		function getNameById(id) {
			const result = specialOffers.find((item) => item._id === id);
			return result ? result.name : '';
		}

		return (
			<Box className={classes.specialOffers}>
				<MultiSelect
					label='Special offers'
					optionLabel='name'
					helperText='Note: Free Products always first'
					options={options}
					value={offer.specialOfferIds.map((id) => ({
						id,
						name: getNameById(id),
					}))}
					onChange={handleSpecialOffersChange}
				/>
			</Box>
		);
	}

	function renderFreeProductsToExclude() {
		function getItemById(id) {
			return freeProducts.find((item) => item._id === id);
		}

		function handleChange(values) {
			const newProducts = [];

			values.forEach((item) => {
				newProducts.push(item);
			});

			handleOfferUpdate({
				freeProductsToExclude: newProducts,
			});
		}

		const options = freeProducts
			.filter((item) => item.active)
			.map((item) => ({
				id: item._id,
				name: `${item.name} ${item.forModal ? ' [modal]' : ' [page]'}`,
			}));

		const value = offer.freeProductsToExclude.map((item) => {
			const freeProduct = getItemById(item._id || item.id);

			return {
				id: freeProduct?._id,
				name: freeProduct?.name + (freeProduct?.forModal ? ' [modal]' : ' [page]'),
			};
		});

		return (
			<Box className={classes.freeProducts}>
				<MultiSelect
					label='Free products to exclude'
					optionLabel='name'
					options={options}
					value={value}
					onChange={handleChange}
				/>
			</Box>
		);
	}

	function renderReviews() {
		function getItemById(id) {
			return reviews.find((item) => item._id === id);
		}

		function handleReviewsChange(values) {
			const newReviews = [];

			values.forEach((item) => {
				newReviews.push(item);
			});

			handleOfferUpdate({
				reviews: newReviews,
			});
		}

		const options = reviews.map(({ _id, name, title, offer }) => ({
			id: _id,
			name: `[${offer.name}] ${title} ${name}`,
		}));

		const value = offer.reviews.map((item) => {
			const review = getItemById(item._id || item.id);

			return {
				id: review._id,
				name: `[${review.offer.name}] ${review.title} ${review.name}`,
			};
		});

		return (
			<Box className={classes.reviews}>
				<MultiSelect
					label='Reviews'
					optionLabel='name'
					options={options}
					value={value}
					onChange={handleReviewsChange}
				/>
			</Box>
		);
	}

	function renderCrossSells() {
		function getItemById(id) {
			return crossSells.find((item) => item._id === id);
		}

		function handleCrossSellsChange(values) {
			const newCrossSells = [];

			values.forEach((item) => {
				newCrossSells.push(item);
			});

			handleOfferUpdate({
				crossSells: newCrossSells,
			});
		}

		const options = crossSells.map((item) => ({
			id: item._id,
			name: item.name,
		}));

		const value = offer.crossSells.map((item) => {
			const crossSell = getItemById(item._id || item.id);

			return {
				id: crossSell?._id,
				name: crossSell?.name,
			};
		});

		return (
			<Box className={classes.crossSells}>
				<MultiSelect
					label='Cross-sells'
					optionLabel='name'
					options={options}
					value={value}
					onChange={handleCrossSellsChange}
				/>
			</Box>
		);
	}

	function renderDefaultVariantSelector() {
		function handleChange(e) {
			handleOfferUpdate({
				defaultVariantId: e.target.value,
			});
		}

		return (
			<Box className={classes.textField}>
				<FormControl fullWidth>
					<InputLabel>Default variant</InputLabel>
					<Select value={offer.defaultVariantId} onChange={handleChange}>
						{offer.productsData.map((variant) => (
							<MenuItem key={variant.id} value={variant.id}>
								{variant.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		);
	}

	function renderTemplateSelector() {
		const templates = [
			{
				id: 1,
				name: 'Default',
			},
			{
				id: 2,
				name: 'One product',
			},
		];

		function handleChange(e) {
			handleOfferUpdate({
				template: e.target.value,
			});
		}

		return (
			<Box className={classes.textField}>
				<FormControl fullWidth>
					<InputLabel>Checkout template</InputLabel>
					<Select value={offer.template} onChange={handleChange}>
						{templates.map((template) => (
							<MenuItem key={template.id} value={template.id}>
								{`[${template.id}] - ${template.name}`}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		);
	}

	const Disclaimer = () => {
		const [text, setText] = useState('');

		useEffect(() => {
			setText(offer.webtvDisclaimer);
		}, []);

		return (
			<TextField
				style={{ marginTop: '30px' }}
				multiline
				minRows={3}
				label='WebTV disclaimer'
				value={text}
				onChange={(e) => setText(e.target.value)}
				onBlur={(e) =>
					handleOfferUpdate({
						webtvDisclaimer: text,
					})
				}
				fullWidth
				className={classes.textField}
			/>
		);
	};

	const Gallery = () => {
		const [gallery, setGallery] = useState([
			{
				source: '',
				thumbnail: '',
				type: 'image',
			},
		]);

		useEffect(() => {
			setGallery(offer.gallery);
		}, [offer]);

		function handleAddGalleryItem() {
			handleOfferUpdate({
				gallery: [
					...gallery,
					{
						source: '',
						thumbnail: '',
						type: 'image',
					},
				],
			});
		}

		function handleUpdateGalleryItem(update, index) {
			const newGallery = [...gallery];
			const entries = Object.entries(update)[0];

			newGallery[index][entries[0]] = entries[1];
			setGallery(newGallery);
			handleOfferUpdate({ gallery: newGallery });
		}

		function handleDeleteGalleryItem(source) {
			const newGallery = gallery.filter((item, index) => item.source !== source);

			handleOfferUpdate({
				gallery:
					newGallery.length > 0
						? newGallery
						: [
								{
									source: '',
									thumbnail: '',
									type: 'image',
								},
						  ],
			});
		}

		return (
			<>
				<Typography variant='subtitle2' gutterBottom>
					Gallery
				</Typography>
				{gallery.map((item, index) => (
					<Box className={classes.products} key={item.source + index + item.type + item.thumbnail}>
						<GalleryItem
							index={index}
							source={item.source}
							type={item.type}
							thumbnail={item.thumbnail}
							sourceHelperText='344x344'
							thumbnailHelperText='62x62'
							onUpdate={handleUpdateGalleryItem}
						/>
						<Box className={classes.iconsGroup}>
							<IconButton
								color='secondary'
								aria-label='Delete'
								component='span'
								size='small'
								onClick={() => handleDeleteGalleryItem(item.source)}
								className={classes.productsIcon}
							>
								<DeleteIcon />
							</IconButton>
							<IconButton
								color='primary'
								aria-label='Add'
								component='span'
								size='small'
								onClick={() => handleAddGalleryItem()}
								className={classes.productsIcon}
							>
								<AddIcon />
							</IconButton>
						</Box>
					</Box>
				))}
				{!gallery.length && (
					<Box className={classes.iconsGroup}>
						<IconButton
							color='primary'
							aria-label='Add'
							component='span'
							size='small'
							onClick={() => handleAddGalleryItem()}
							className={classes.productsIcon}
						>
							<AddIcon />
						</IconButton>
					</Box>
				)}
			</>
		);
	};

	const ProtectionSettings = () => {
		const [price, setPrice] = useState('');

		useEffect(() => {
			setPrice(offer.protectionPrice);
		}, [offer]);

		return (
			<>
				<Typography variant='subtitle2' gutterBottom>
					Protection Package (PID 2560)
				</Typography>

				<TextField
					type='number'
					label='Price per unit in USD'
					helperText='Example: 0.99. Leave empty to disable protection package.'
					value={price}
					onChange={(e) => setPrice(e.target.value)}
					onBlur={(e) =>
						handleOfferUpdate({
							protectionPrice: e.target.value,
						})
					}
					fullWidth
					className={classes.textField}
				/>
			</>
		);
	};

	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role='tabpanel'
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box sx={{ p: 3 }}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	const OfferSettings = () => {
		const [name, setName] = useState('');

		useEffect(() => {
			setName(offer.name);
		}, [offer]);

		return (
			<TabPanel value={value} index={0}>
				<Paper className={classes.paper}>
					<TextField
						label='Offer id'
						value={offer._id}
						fullWidth
						InputProps={{
							readOnly: true,
						}}
						className={classes.textField}
					/>
					<TextField
						label='Offer name'
						value={name}
						onChange={(e) => setName(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								name: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<ChipsList
						items={offer.productIds}
						onUpdate={(newItems) => handleOfferUpdate({ productIds: newItems })}
						title='Product Ids'
					/>
					<FormControl className={classes.textField}>
						<InputLabel>Products sorting</InputLabel>
						<Select
							value={offer.productsSorting}
							onChange={(e) => {
								handleOfferUpdate({
									productsSorting: e.target.value,
								});
							}}
						>
							<MenuItem value={'asc'}>Ascending</MenuItem>
							<MenuItem value={'desc'}>Descending</MenuItem>
						</Select>
					</FormControl>
					{renderDefaultVariantSelector()}
					{renderTemplateSelector()}
					{offer.productIds.length > 0 && (
						<ProductsList
							offer={offer}
							onSyncClick={() => handleOfferUpdate({ productIds: offer.productIds })}
							onProductClick={(productIds) =>
								handleOfferUpdate({
									mostPopularProductIds: productIds,
								})
							}
						/>
					)}
					{renderReviews()}
					{renderSpecialOffers()}
					{renderFreeProductsToExclude()}
					{renderCrossSells()}
					<ProtectionSettings />
				</Paper>
			</TabPanel>
		);
	};

	const CheckoutSettings = () => {
		const [logoSrc, setLogoSrc] = useState('');
		const [faviconSrc, setFaviconSrc] = useState('');
		const [title, setTitle] = useState('');
		const [brand, setBrand] = useState('');
		const [ratings, setRatings] = useState('');
		const [category, setCategory] = useState('');
		const [productImageSrc, setProductImageSrc] = useState('');

		useEffect(() => {
			setTitle(offer.title);
			setLogoSrc(offer.logoSrc);
			setFaviconSrc(offer.faviconSrc);
			setCategory(offer.category);
			setBrand(offer.brand);
			setRatings(offer.ratings);
			setProductImageSrc(offer.productImageSrc);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [offer]);

		return (
			<TabPanel value={value} index={1}>
				<Paper className={classes.paper}>
					<ImageInput
						value={logoSrc}
						label='Logo image url'
						onChange={(e) => setLogoSrc(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								logoSrc: e.target.value,
							})
						}
					/>
					<ImageInput
						label='Favicon source url'
						value={faviconSrc}
						onChange={(e) => setFaviconSrc(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								faviconSrc: e.target.value,
							})
						}
					/>
					<TextField
						label='Checkout title'
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								title: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Brand'
						value={brand}
						onChange={(e) => setBrand(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								brand: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Ratings/delivered'
						value={ratings}
						onChange={(e) => setRatings(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								ratings: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Best in category'
						helperText='Example: Diagnostic tools'
						value={category}
						onChange={(e) => setCategory(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								category: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<ImageInput
						label='Product image url'
						value={productImageSrc}
						onChange={(e) => setProductImageSrc(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								productImageSrc: e.target.value,
							})
						}
					/>
					<Gallery />
					<ChipsList
						items={offer.benefits}
						onUpdate={(newItems) => handleOfferUpdate({ benefits: newItems })}
						title='Benefits'
					/>
					<PreloaderSettings />

					<CompanySettings />
					<FormControlLabel
						className={classes.switch}
						label='Disable top banner'
						labelPlacement='end'
						control={
							<Switch
								checked={offer.isBannerDisabled}
								color='primary'
								onChange={(e) =>
									handleOfferUpdate({
										isBannerDisabled: e.target.checked,
									})
								}
								name='Disable top banner'
							/>
						}
					/>
					<Disclaimer />
				</Paper>
			</TabPanel>
		);
	};

	const ExternalSettings = () => {
		const [gtmId, setGtmId] = useState('');
		const [gaId, setGaId] = useState('');
		const [gaMeasurementId, setGaMeasurementId] = useState('');
		const [gaApiSecret, setGaApiSecret] = useState('');
		const [campaignId, setCampaignId] = useState('');
		const [fallbackCampaignId, setFallbackCampaignId] = useState('');
		const [everflowOfferId, setEverflowOfferId] = useState('');
		const [klaviyoProspectListId, setProspectList] = useState('');
		const [klaviyoCustomerListId, setCustomerList] = useState('');
		const [klaviyoSmsListId, setKlaviyoSmsListId] = useState('');
		const [klaviyoPrivateKey, setKlaviyoPrivateKey] = useState('');
		const [klaviyoPublicKey, setKlaviyoPublicKey] = useState('');

		useEffect(() => {
			setGtmId(offer.gtmId);
			setGaId(offer.gaId);
			setGaMeasurementId(offer.gaMeasurementId);
			setGaApiSecret(offer.gaApiSecret);
			setCampaignId(offer.campaignId);
			setFallbackCampaignId(offer.fallbackCampaignId);
			setEverflowOfferId(offer.everflowOfferId);
			setProspectList(offer.klaviyoProspectListId);
			setCustomerList(offer.klaviyoCustomerListId);
			setKlaviyoSmsListId(offer.klaviyoSmsListId);
			setKlaviyoPrivateKey(offer.klaviyoPrivateKey);
			setKlaviyoPublicKey(offer.klaviyoPublicKey);
		}, [offer]);

		return (
			<TabPanel value={value} index={2}>
				<Paper className={classes.paper}>
					<TextField
						label='Google Tag Manager id'
						value={gtmId}
						onChange={(e) => setGtmId(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								gtmId: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Google UA id'
						value={gaId}
						onChange={(e) => setGaId(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								gaId: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='GA4 Measurement Id'
						helperText='G-XXXXXXXXXX'
						value={gaMeasurementId}
						onChange={(e) => setGaMeasurementId(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								gaMeasurementId: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='GA4 API Secret'
						helperText='GA Admin->Data Streams->Details->Measurement Protocol API secrets'
						value={gaApiSecret}
						onChange={(e) => setGaApiSecret(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								gaApiSecret: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Sticky campaign id'
						value={campaignId}
						onChange={(e) => setCampaignId(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								campaignId: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Sticky fallback campaign id'
						value={fallbackCampaignId}
						onChange={(e) => setFallbackCampaignId(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								fallbackCampaignId: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Everflow offer id'
						helperText='PLEASE USE OID URL PARAMETER FOR EVERFLOW TRACKING'
						value={everflowOfferId}
						InputProps={{
							readOnly: true,
						}}
						onChange={(e) => setEverflowOfferId(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								everflowOfferId: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Klaviyo public key'
						value={klaviyoPublicKey}
						onChange={(e) => setKlaviyoPublicKey(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								klaviyoPublicKey: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Klaviyo private key'
						value={klaviyoPrivateKey}
						onChange={(e) => setKlaviyoPrivateKey(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								klaviyoPrivateKey: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Klaviyo prospect list id'
						value={klaviyoProspectListId}
						onChange={(e) => setProspectList(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								klaviyoProspectListId: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Klaviyo customer list id'
						value={klaviyoCustomerListId}
						onChange={(e) => setCustomerList(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								klaviyoCustomerListId: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Klaviyo SMS list id'
						value={klaviyoSmsListId}
						onChange={(e) => setKlaviyoSmsListId(e.target.value)}
						onBlur={(e) =>
							handleOfferUpdate({
								klaviyoSmsListId: e.target.value,
							})
						}
						fullWidth
						className={classes.textField}
					/>

					<FbPixels />
					<TikTokPixels />
				</Paper>
			</TabPanel>
		);
	};

	function renderPageActionButtons() {
		async function onCloneClick() {
			const clone = offer;
			delete clone.createdAt;
			delete clone._id;
			delete clone.__v;
			clone.name += ' clone';

			try {
				const created = await createOffer(clone);
				history.push({});
				history.push(`/offers/${created._id}`);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('error: ', error);
			}
		}

		return (
			<Box className={classes.pageActionButtons}>
				<Button
					variant='contained'
					color='primary'
					endIcon={<ContentCopyIcon />}
					onClick={() => onCloneClick()}
				>
					Clone
				</Button>
			</Box>
		);
	}

	return (
		<Container maxWidth='lg' className={classes.container}>
			{renderPageActionButtons()}
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={handleChange}>
					<Tab label='Offer settings' {...a11yProps(0)} />
					<Tab label='Checkout settings' {...a11yProps(1)} />
					<Tab label='External settings' {...a11yProps(2)} />
				</Tabs>
				{offerLoading && <LinearProgress className={classes.progress} />}
			</Box>
			<OfferSettings />
			<CheckoutSettings />
			<ExternalSettings />
		</Container>
	);
}

OfferPage.propTypes = {
	onRender: PropTypes.func,
};
