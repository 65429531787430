import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import ChipsList from '../../components/ChipsList/ChipsList';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import ProductsList from '../../components/ProductsList/ProductsList';
import { Context as UserContext } from '../../context/UserContext';
import { Context as PromoContext } from '../../context/PromoContext';
import { Context as OffersContext } from '../../context/OffersContext';
import { Context as SpecialOffersContext } from '../../context/SpecialOffersContext';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	paper: {
		width: '100%',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	container: {
		marginTop: theme.spacing(3),
	},
	specialOffers: {
		marginBottom: theme.spacing(3),
	},
	progress: {
		marginTop: theme.spacing(2),
	},
	textField: {
		flex: 1,
		marginBottom: theme.spacing(3),
	},
	products: {
		display: 'flex',
	},
	productsIcon: {
		alignSelf: 'center',
		marginLeft: theme.spacing(1),
	},
	formGroup: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'nowrap',
		marginBottom: theme.spacing(3),
		'& $textField': {
			marginBottom: 0,
		},
		'& $chipsInput': {
			marginBottom: 0,
			marginTop: 0,
		},
	},
	formGroupSwitch: {
		marginLeft: 0,
	},
}));

export default function PromoPage({ onRender }) {
	// VARS
	const classes = useStyles();
	const { id } = useParams();

	// STATE
	const [switchesState, setSwitchesState] = useState({
		productImageSrc: false,
		offerId: false,
		campaignId: false,
		title: false,
		category: false,
		specialOfferIds: false,
		productIds: false,
	});

	const [promoState, setPromoState] = useState({
		name: '',
		description: '',
		productImageSrc: '',
		offerId: '',
		campaignId: '',
		title: '',
		category: '',
		specialOfferIds: [],
		productIds: [],
	});

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { promo, loading: promoLoading, error: promoError },
		loadPromo,
		updatePromo,
	} = useContext(PromoContext);

	const {
		state: { offers, loading: offersLoading, error: offersError },
		loadOffers,
	} = useContext(OffersContext);

	const {
		state: { specialOffers, loading: specialOffersLoading },
		loadSpecialOffers,
	} = useContext(SpecialOffersContext);

	// EFFECTS
	useEffect(() => {
		if (user) {
			loadPromo(id);
			loadOffers();
			loadSpecialOffers();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (promo) {
			onRender(`Promo - ${promo.name}`);
			// Set values after promo is loaded
			setStateForPromo(promo, setPromoState);
			setStateForSwitches(promo, setSwitchesState);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [promo]);

	function setStateForSwitches(obj) {
		const keys = Object.keys(obj);
		const newState = {};

		keys.forEach((key) => {
			newState[key] = typeof obj[key] === 'number' ? true : obj[key].length > 0;
		});

		setSwitchesState({
			...switchesState,
			...newState,
		});
	}

	function setStateForPromo(obj) {
		setPromoState({
			...promoState,
			...obj,
		});
	}

	function handlePromoUpdate(update) {
		updatePromo(id, update);
	}

	function handlePromoChange(event) {
		setPromoState({
			...promoState,
			[event.target.name]: event.target.value,
		});
	}

	function handleSwitchChange(event) {
		setSwitchesState({
			...switchesState,
			[event.target.name]: event.target.checked,
		});

		if (!event.target.checked) {
			handlePromoUpdate({
				[event.target.name]: typeof promoState[event.target.name] === 'object' ? [] : '',
			});
		}
	}

	if (promoError || offersError) {
		return <Error error={promoError || offersError} />;
	}

	if (offersLoading || offersLoading || specialOffersLoading) {
		return <Loading />;
	}

	if (!promo) {
		return null;
	}

	function renderSpecialOffers() {
		function handleSpecialOffersChange(values) {
			const newValues = values.map((item) => item.id);

			updatePromo(id, {
				specialOfferIds: newValues,
			});
		}

		const options = specialOffers.map((item) => ({
			id: item._id,
			name: item.name,
		}));

		function getNameById(id) {
			const result = specialOffers.find((item) => item._id === id);
			return result ? result.name : '';
		}

		return (
			<FormGroup row className={classes.formGroup}>
				<FormControlLabel
					labelPlacement='top'
					className={classes.formGroupSwitch}
					control={
						<Switch
							checked={switchesState.specialOfferIds}
							onChange={handleSwitchChange}
							name='specialOfferIds'
							color='primary'
						/>
					}
				/>
				<FormControl className={classes.textField} fullWidth>
					<MultiSelect
						label='Special offers'
						optionLabel='name'
						options={options}
						value={promo.specialOfferIds.map((id) => ({
							id,
							name: getNameById(id),
						}))}
						onChange={handleSpecialOffersChange}
					/>
				</FormControl>
			</FormGroup>
		);
	}

	return (
		<>
			{promoLoading && <LinearProgress className={classes.progress} />}
			<Container maxWidth='lg' className={classes.container}>
				<Paper className={classes.paper}>
					<TextField
						label='Id'
						value={promo._id}
						fullWidth
						InputProps={{
							readOnly: true,
						}}
						className={classes.textField}
					/>
					<FormControl className={classes.textField} fullWidth>
						<InputLabel shrink id='demo-simple-select-placeholder-label-label'>
							Offer
						</InputLabel>
						<Select
							labelId='offer-select'
							id='offer-select'
							value={promoState.offerId}
							onChange={(e) =>
								updatePromo(id, {
									offerId: e.target.value,
								})
							}
							name='offerId'
						>
							{offers.map((item, index) => (
								<MenuItem key={index} value={item._id}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						label='Promo name'
						value={promoState.name}
						name='name'
						onChange={handlePromoChange}
						onBlur={() =>
							handlePromoUpdate({
								name: promoState.name,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Promo description'
						name='description'
						value={promoState.description}
						onChange={handlePromoChange}
						onBlur={() =>
							handlePromoUpdate({
								description: promoState.description,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<FormGroup row className={classes.formGroup}>
						<FormControlLabel
							labelPlacement='top'
							className={classes.formGroupSwitch}
							control={
								<Switch
									checked={switchesState.productImageSrc}
									onChange={handleSwitchChange}
									name='productImageSrc'
									color='primary'
								/>
							}
						/>
						<TextField
							disabled={!switchesState.productImageSrc}
							label='Product image url'
							name='productImageSrc'
							value={promoState.productImageSrc}
							onChange={handlePromoChange}
							onBlur={() =>
								handlePromoUpdate({
									productImageSrc: promoState.productImageSrc,
								})
							}
							className={classes.textField}
						/>
					</FormGroup>

					{promoState.productImageSrc && (
						<Box>
							<img
								src={promoState.productImageSrc}
								height='100'
								alt='product'
								style={{ marginBottom: '15px' }}
							/>
						</Box>
					)}
					<FormGroup row className={classes.formGroup}>
						<FormControlLabel
							labelPlacement='top'
							className={classes.formGroupSwitch}
							control={
								<Switch
									checked={switchesState.title}
									onChange={handleSwitchChange}
									name='title'
									color='primary'
								/>
							}
						/>
						<TextField
							disabled={!switchesState.title}
							label='Product name'
							value={promoState.title}
							name='title'
							onChange={handlePromoChange}
							onBlur={() =>
								handlePromoUpdate({
									title: promoState.title,
								})
							}
							fullWidth
							className={classes.textField}
						/>
					</FormGroup>
					<FormGroup row className={classes.formGroup}>
						<FormControlLabel
							labelPlacement='top'
							className={classes.formGroupSwitch}
							control={
								<Switch
									checked={switchesState.category}
									onChange={handleSwitchChange}
									name='category'
									color='primary'
								/>
							}
						/>
						<TextField
							disabled={!switchesState.category}
							label='Best in category'
							value={promoState.category}
							name='category'
							onChange={handlePromoChange}
							onBlur={() =>
								handlePromoUpdate({
									category: promoState.category,
								})
							}
							fullWidth
							className={classes.textField}
						/>
					</FormGroup>
					<FormGroup row className={classes.formGroup}>
						<FormControlLabel
							labelPlacement='top'
							className={classes.formGroupSwitch}
							control={
								<Switch
									checked={switchesState.campaignId}
									onChange={handleSwitchChange}
									name='campaignId'
									color='primary'
								/>
							}
						/>
						<TextField
							label='Sticky campaign id'
							disabled={!switchesState.campaignId}
							name='campaignId'
							value={promoState.campaignId}
							onChange={handlePromoChange}
							onBlur={(e) =>
								handlePromoUpdate({
									campaignId: e.target.value,
								})
							}
							fullWidth
							className={classes.textField}
						/>
					</FormGroup>
					{renderSpecialOffers()}
					<FormGroup row className={classes.formGroup}>
						<FormControlLabel
							labelPlacement='top'
							className={classes.formGroupSwitch}
							control={
								<Switch
									checked={switchesState.benefits || false}
									onChange={handleSwitchChange}
									name='benefits'
									color='primary'
								/>
							}
						/>
						<ChipsList
							items={promoState.benefits}
							onUpdate={(newItems) => handlePromoUpdate({ benefits: newItems })}
							title='Benefits'
						/>
					</FormGroup>
					<FormGroup row className={classes.formGroup}>
						<FormControlLabel
							labelPlacement='top'
							className={classes.formGroupSwitch}
							control={
								<Switch
									checked={switchesState.productIds}
									onChange={handleSwitchChange}
									name='productIds'
									color='primary'
								/>
							}
						/>
						<ChipsList
							items={promoState.productIds}
							onUpdate={(newItems) => handlePromoUpdate({ productIds: newItems })}
							title='Product Ids'
						/>
					</FormGroup>
					{promoState.productIds.length > 0 && (
						<ProductsList
							offer={promoState}
							onSyncClick={() => handlePromoUpdate({ productIds: promoState.productIds })}
							onProductClick={(productIds) =>
								handlePromoUpdate({
									mostPopularProductIds: productIds,
								})
							}
						/>
					)}
				</Paper>
			</Container>
		</>
	);
}

PromoPage.propTypes = {
	onRender: PropTypes.func,
};
