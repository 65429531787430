/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import { Context as BannersContext } from '../../context/BannersContext';
import { Context as UserContext } from '../../context/UserContext';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DatePicker from '../../components/DatePicker/DatePicker';
import Modal from '../../components/Modal/Modal';
import ImageInput from '../../components/ImageInput/ImageInput';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 750,
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
		padding: theme.spacing(2),
	},
	textField: {
		marginBottom: theme.spacing(3),
	},
	topButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(4),
	},
	modalButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(3),
	},
	datePicker: {
		marginBottom: theme.spacing(3),
	},
	bannerButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
}));

export default function BannersPage({ onRender }) {
	const classes = useStyles();
	// STATE
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [bannerToDelete, setBannerToDelete] = useState(null);

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { banners, loading: bannersLoading, error: bannersError },
		loadBanners,
		deleteBanner,
		createBanner,
		updateBanner,
	} = useContext(BannersContext);

	// EFFECTS
	useEffect(() => {
		if (user) {
			loadBanners();
		}

		onRender('Banners');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	if (bannersError) {
		return <Error error={bannersError} />;
	}

	if (bannersLoading) {
		return <Loading />;
	}

	function handleUpdateBanner(id, update) {
		updateBanner(id, update);
	}

	function handleDeleteBanner(id) {
		setDeleteModalOpen(true);
		setBannerToDelete(id);
	}

	const Banner = ({ data }) => {
		const [state, setState] = useState(data);

		function handleStartDateChange(date) {
			handleUpdateBanner(data._id, {
				...state,
				start: moment(date).set({ hour: 0, minute: 0, second: 1 }).toISOString(),
			});
		}

		function handleEndDateChange(date) {
			handleUpdateBanner(data._id, {
				...state,
				end: moment(date).set({ hour: 0, minute: 0, second: 1 }).toISOString(),
			});
		}

		return (
			<Paper className={classes.paper}>
				<TextField
					fullWidth
					label='Name'
					value={state.name}
					onChange={(e) =>
						setState({
							...state,
							name: e.target.value,
						})
					}
					onBlur={() => handleUpdateBanner(data._id, state)}
					className={classes.textField}
				/>
				<Box className={classes.datePicker}>
					<DatePicker
						align='left'
						startDate={data.start}
						endDate={data.end}
						onStartDateChange={handleStartDateChange}
						onEndDateChange={handleEndDateChange}
					/>
				</Box>
				<ImageInput
					name='imageDesk'
					label='Desktop image'
					value={state.imageDesk}
					className={classes.textField}
					onChange={(e) =>
						setState({
							...state,
							imageDesk: e.target.value,
						})
					}
					onBlur={() => handleUpdateBanner(data._id, state)}
				/>
				<ImageInput
					name='imageMob'
					label='Mobile image'
					value={state.imageMob}
					className={classes.textField}
					onChange={(e) =>
						setState({
							...state,
							imageMob: e.target.value,
						})
					}
					onBlur={() => handleUpdateBanner(data._id, state)}
				/>
				<Box className={classes.bannerButtons}>
					<Button variant='contained' color='secondary' onClick={() => handleDeleteBanner(data._id)}>
						Delete
					</Button>
				</Box>
			</Paper>
		);
	};

	function renderBanners() {
		return banners.map((item, index) => <Banner data={item} key={index} />);
	}

	return (
		<>
			<Modal open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
				<Box>
					<Typography variant='h6' gutterBottom>
						Are you sure you want to delete this banner?
					</Typography>
				</Box>
				<Box className={classes.modalButtons}>
					<Button
						variant='contained'
						color='primary'
						style={{ marginRight: '10px' }}
						onClick={async () => {
							setDeleteModalOpen(false);
						}}
					>
						No
					</Button>
					<Button
						variant='contained'
						color='secondary'
						onClick={async () => {
							await deleteBanner(bannerToDelete);
							setDeleteModalOpen(false);
						}}
					>
						Yes
					</Button>
				</Box>
			</Modal>
			<Container className={classes.container}>
				<Box className={classes.topButtons}>
					<Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => createBanner()}>
						Add
					</Button>
				</Box>
				{renderBanners()}
			</Container>
		</>
	);
}
