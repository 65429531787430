import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uaParserJs from 'ua-parser-js';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import Modal from '../../components/Modal/Modal';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import Link from '@material-ui/core/Link';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';
import { getCookie } from '../../utils/helpers';

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: 'createdAt',
		label: 'Time',
	},
	{
		id: 'offerName',
		label: 'Offer',
	},
	{
		id: 'processedResponseData.order_id',
		label: 'Sticky Order Id',
	},
	{
		id: 'email',
		label: 'Email',
	},
	{
		id: 'affId',
		label: 'Aff id',
	},
	{
		id: 'last4digits',
		label: 'Last 4 digits',
		align: 'right',
	},
	{
		align: 'right',
		label: 'Status',
	},
	{
		align: 'right',
		label: 'Delete',
	},
];

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell></TableCell>
				{headCells.map((headCell, index) => (
					<TableCell
						key={index}
						align={headCell.align || 'left'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	modalButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(3),
	},
}));

export default function OrdersTable({ orders, onDeleteOrder, ordersTotal, pagination, onPaginationChange }) {
	const parser = new uaParserJs();
	const classes = useStyles();
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('createdAt');
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [orderToDelete, setOrderToDelete] = useState(null);

	function handleRequestSort(event, property) {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	}

	function handleChangePage(event, newPage) {
		onPaginationChange({
			...pagination,
			page: newPage,
		});
	}

	function handleChangeRowsPerPage(event) {
		onPaginationChange({
			limit: event.target.value,
			page: 0,
		});
	}

	function handleDeleteOrder(id) {
		setDeleteModalOpen(true);
		setOrderToDelete(id);
	}

	function renderTransactionStatus(row) {
		const isApproved =
			row.processedResponseData?.response_code === '100' || row.processedResponseData?.responsecode === '100';
		const declinedMessage =
			row.processedResponseData?.error_message || row.processedResponseData?.errormessage;

		if (isApproved) {
			return (
				<Tooltip title='Transaction approved'>
					<DoneIcon style={{ color: 'green' }} />
				</Tooltip>
			);
		}

		return (
			<>
				<Tooltip
					title={
						declinedMessage || 'Transaction status not available. This order has not yet been processed.'
					}
				>
					<ClearIcon color='error' />
				</Tooltip>
			</>
		);
	}

	function renderCookie(str) {
		if (!str) {
			return null;
		}
		const values = str.split(';').sort((a, b) => a[2].localeCompare(b[2]));

		return values.map((item, index) => (
			<Typography key={index} variant='caption' display='block' gutterBottom>
				{item}
			</Typography>
		));
	}

	function renderDeviceInfo(str) {
		parser.setUA(str);
		const result = parser.getResult();

		return (
			<Box>
				<Typography variant='caption' display='block' gutterBottom>
					{result.browser.name} {result.browser.version} | {result.os.name} {result.os.version} |{' '}
					{result.device.vendor} {result.device.model} {result.device.type}
				</Typography>
			</Box>
		);
	}

	function getOrderId(order) {
		if (!order?.processedResponseData) return null;

		return order.processedResponseData.order_id || order.processedResponseData.orderid;
	}

	function Row(props) {
		const { row } = props;
		const [open, setOpen] = useState(false);

		return (
			<>
				<TableRow key={row._id}>
					<TableCell>
						<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
					<TableCell component='th'>
						<Typography variant='caption' gutterBottom style={{ whiteSpace: 'nowrap' }}>
							<Tooltip title='Created'>
								<span>{moment(row.createdAt).format('MMM DD HH:mm')}</span>
							</Tooltip>
							{' -> '}
							<Tooltip title='Finished'>
								<span>{moment(row.updatedAt).format('MMM DD HH:mm')}</span>
							</Tooltip>
						</Typography>
					</TableCell>
					<TableCell>{row.offerName}</TableCell>
					<TableCell>
						{getOrderId(row) && (
							<Link
								target='_blank'
								href={`https://rimcrm.sticky.io/admin/orders.php?show_details=show_details&show_folder=view_all&fromPost=1&act=&sequence=1&show_by_id=${getOrderId(
									row
								)}`}
							>
								{getOrderId(row)}
							</Link>
						)}
					</TableCell>
					<TableCell>{row.email}</TableCell>
					<TableCell>{row.affId}</TableCell>
					<TableCell align='right'>{getCookie(row?.cookie, 'last4digits')}</TableCell>
					<TableCell align='right'>
						{row.flowIsComplete ? (
							<Tooltip title='Flow completed'>
								<DoneIcon style={{ color: 'green' }} />
							</Tooltip>
						) : (
							<Tooltip title='Flow not completed'>
								<ClearIcon color='error' />
							</Tooltip>
						)}
						{row.processed && (
							<Tooltip title='Order processed'>
								<DoneIcon style={{ color: 'green' }} />
							</Tooltip>
						)}
						{!row.processed && (
							<Tooltip title='Order not processed'>
								<ClearIcon color='error' />
							</Tooltip>
						)}
						{!row.processed && row.skipped && (
							<Tooltip title='Order was skipped by cron'>
								<NotInterestedIcon color='disabled' />
							</Tooltip>
						)}

						{!row.skipped && renderTransactionStatus(row)}
						{row.isPayPalOrder && (
							<img
								height={18}
								src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAbwAAAG8B8aLcQwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAGbSURBVDiNfZE/SBthGMZ/7+XOi7XaiihoFSpqomgRFARFEBxcpQXBxcHR0dUlOOmevTgUWpdSxM6dxKGDs7oJ/icYrcYmucvrEC53n1584eD93nue3z3vfaKqBCXDmwso34EEL+sUdAe3tKYHmXwwtAyJymIdM8AHkBWK7i8RJB6ADtcxR2uG9MZUcLBr8QeyLg79hrRUAPUjAwHbBdv5BOyZCRL/0kb8/3fwkINCPvLcwN0FdLeuyfJW0gSIZcb3ivELiAWToz1YbV+fATABfjkekOqHBgfeuBMmAAkBWoGK/8JLZxfMTVf7sncGkZ9o3IBfrkYVAduBlmYYG4F0bygvFLcBRFWR3vUkje5DLVF7C8yOgxPhR+s8d6zZuVS4QpMzaKzzsSverAonV/tc3o4Fo6pKn93A+6awv38scZ3/jecd8Kjb+uPzUVQafMYEvHsb9me5Td2az8TvEsZurk2S7inJhlDh+bv1zCGgo7hKxRrE1yGGerK1t2VP9duXv68BbAD9k/GAQwBZ+tmH7yuJhHCdP37NDPAELRh8ed/8ocsAAAAASUVORK5CYII='
								alt='PayPal Logo'
							/>
						)}
					</TableCell>
					<TableCell align='right'>
						<IconButton
							color='secondary'
							aria-label='Delete order'
							component='span'
							className={classes.deleteIcon}
							onClick={() => handleDeleteOrder(row._id)}
						>
							<DeleteIcon />
						</IconButton>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
						<Collapse in={open} timeout='auto' unmountOnExit>
							<Box margin={1}>
								<Typography variant='h6' gutterBottom component='div'>
									Order details
								</Typography>
								<Table size='small' aria-label='purchases'>
									<TableBody>
										<TableRow>
											<TableCell>Everflow transaction id:</TableCell>
											<TableCell>{row.everflowTransactionId}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Order source URL:</TableCell>
											<TableCell
												style={{
													wordBreak: 'break-all',
												}}
											>
												{row.orderSourceUrl}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>User cookies:</TableCell>
											<TableCell
												style={{
													wordBreak: 'break-all',
												}}
											>
												{renderCookie(row.cookie)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Device info:</TableCell>
											<TableCell
												style={{
													wordBreak: 'break-all',
												}}
											>
												{renderDeviceInfo(row.userAgent)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Order total:</TableCell>
											<TableCell>
												{row.processedResponseData &&
													row.processedResponseData.orderTotal &&
													`$${row.processedResponseData.orderTotal}`}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			</>
		);
	}

	return (
		<div className={classes.root}>
			<Modal open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
				<Box>
					<Typography variant='h6' gutterBottom>
						Are you sure you want to delete this order?
					</Typography>
				</Box>
				<Box className={classes.modalButtons}>
					<Button
						variant='contained'
						color='primary'
						style={{ marginRight: '10px' }}
						onClick={async () => {
							setDeleteModalOpen(false);
						}}
					>
						No
					</Button>
					<Button
						variant='contained'
						color='secondary'
						onClick={async () => {
							await onDeleteOrder(orderToDelete);
							setDeleteModalOpen(false);
						}}
					>
						Yes
					</Button>
				</Box>
			</Modal>
			<Paper className={classes.paper}>
				<TableContainer>
					<Table className={classes.table} aria-labelledby='tableTitle' aria-label='enhanced table'>
						<EnhancedTableHead
							classes={classes}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={ordersTotal}
						/>
						<TableBody>
							{stableSort(orders, getComparator(order, orderBy)).map((row, index) => (
								<Row key={index} row={row} />
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component='div'
					count={ordersTotal}
					rowsPerPageOptions={[25, 50, 100, 500, 1000]}
					rowsPerPage={pagination.limit}
					page={pagination.page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	);
}
