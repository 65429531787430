import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Box, Collapse, IconButton, LinearProgress, Link } from '@material-ui/core';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 650,
	},
	paper: {
		width: '100%',
	},
	root: {
		width: '100%',
		marginTop: theme.spacing(2),
	},
	accordion: {
		marginBottom: theme.spacing(2),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
}));

export default function Analytics({ data, loading }) {
	const classes = useStyles();

	function renderCrossSellsTakeRate() {
		if (!data.crossSellsTakeRate) return null;

		return (
			<div className={classes.root}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography className={classes.heading} variant='subtitle1'>
							Cross-sells take rates
						</Typography>
					</AccordionSummary>
					<AccordionDetails style={{ padding: 0 }}>
						<TableContainer className={classes.paper}>
							<Table className={classes.table} aria-label='simple table'>
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell align='right'>Purchases</TableCell>
										<TableCell align='right'>Take rate</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.crossSellsTakeRate.map((row) => (
										<TableRow key={row[0]}>
											<TableCell component='th' scope='row'>
												{row[0]}
											</TableCell>
											<TableCell align='right'>{row[1].number}</TableCell>
											<TableCell align='right'>{Math.round(row[1].percentage * 100) / 100}%</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	}

	function renderFreeProductsTakeRate() {
		if (!data.freeProductsTakeRate) return null;

		return (
			<div className={classes.root}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography className={classes.heading} variant='subtitle1'>
							Free product take rates
						</Typography>
					</AccordionSummary>
					<AccordionDetails style={{ padding: 0 }}>
						<TableContainer className={classes.paper}>
							<Table className={classes.table} aria-label='simple table'>
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell align='right'>Purchases</TableCell>
										<TableCell align='right'>Take rate</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.freeProductsTakeRate.map((row) => (
										<TableRow key={row[0]}>
											<TableCell component='th' scope='row'>
												{row[0]}
											</TableCell>
											<TableCell align='right'>{row[1].number}</TableCell>
											<TableCell align='right'>{Math.round(row[1].percentage * 100) / 100}%</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	}

	function renderSpecialOfferTakeRates() {
		if (!data.specialOffersTakeRate) return null;

		return (
			<div className={classes.root}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography className={classes.heading} variant='subtitle1'>
							Special offer take rates
						</Typography>
					</AccordionSummary>
					<AccordionDetails style={{ padding: 0 }}>
						<TableContainer className={classes.paper}>
							<Table className={classes.table} aria-label='simple table'>
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell align='right'>Position</TableCell>
										<TableCell align='right'>Purchases</TableCell>
										<TableCell align='right'>Take rate</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.specialOffersTakeRate.map((row) => (
										<TableRow key={row[0]}>
											<TableCell component='th' scope='row'>
												{row[0]}
											</TableCell>
											<TableCell align='right'>{row[1].index}</TableCell>
											<TableCell align='right'>{row[1].number}</TableCell>
											<TableCell align='right'>{Math.round(row[1].percentage * 100) / 100}%</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	}

	function renderQtyTakeRate() {
		if (!data.qtyTakeRate) return null;

		return (
			<div className={classes.root}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography className={classes.heading} variant='subtitle1'>
							Product quantities take rates
						</Typography>
					</AccordionSummary>
					<AccordionDetails style={{ padding: 0 }}>
						<TableContainer className={classes.paper}>
							<Table className={classes.table} aria-label='simple table'>
								<TableHead>
									<TableRow>
										<TableCell>Quantity</TableCell>
										<TableCell align='right'>Purchases</TableCell>
										<TableCell align='right'>Take rate</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.qtyTakeRate.map((row) => (
										<TableRow key={row[0]}>
											<TableCell component='th' scope='row'>
												{row[0]}
											</TableCell>
											<TableCell align='right'>{row[1].number}</TableCell>
											<TableCell align='right'>{Math.round(row[1].percentage * 100) / 100}%</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	}

	function renderDeclines() {
		if (!data.declines) return null;

		const Row = ({ row }) => {
			const [open, setOpen] = useState(false);

			return (
				<>
					<TableRow key={row[0]}>
						<TableCell>
							<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
								{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</IconButton>
						</TableCell>
						<TableCell component='th' scope='row'>
							{row[0]}
						</TableCell>
						<TableCell align='right'>{row[1].number}</TableCell>
						<TableCell align='right'>{Math.round(row[1].percentage * 100) / 100}%</TableCell>
					</TableRow>
					<TableRow>
						<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
							<Collapse in={open} timeout='auto' unmountOnExit>
								<Box margin={1}>
									<Table size='small' aria-label='purchases'>
										<TableHead>
											<TableRow>
												<TableCell>Email</TableCell>
												<TableCell>Sticky order id</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{row[1].orders.map((order, index) => (
												<TableRow>
													<TableCell>{order.email}</TableCell>
													<TableCell>
														<Link
															target='_blank'
															href={`https://rimcrm.sticky.io/admin/orders.php?show_details=show_details&show_folder=view_all&fromPost=1&act=&sequence=1&show_by_id=${order.stickyOrderId}`}
														>
															{order.stickyOrderId}
														</Link>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</Box>
							</Collapse>
						</TableCell>
					</TableRow>
				</>
			);
		};

		return (
			<div className={classes.root}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography className={classes.heading} variant='subtitle1'>
							Declines ({data.declines.reduce((acc, item) => acc + item[1].orders.length, 0)})
						</Typography>
					</AccordionSummary>
					<AccordionDetails style={{ padding: 0 }}>
						<TableContainer className={classes.paper}>
							<Table className={classes.table} aria-label='simple table'>
								<TableHead>
									<TableRow>
										<TableCell></TableCell>
										<TableCell>Reason</TableCell>
										<TableCell align='right'>Number</TableCell>
										<TableCell align='right'>Percentage</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.declines.map((item, index) => (
										<Row row={item} key={index} />
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	}

	if (loading) {
		return <LinearProgress />;
	}

	return (
		<>
			{renderCrossSellsTakeRate()}
			{renderFreeProductsTakeRate()}
			{renderSpecialOfferTakeRates()}
			{renderQtyTakeRate()}
			{renderDeclines()}
		</>
	);
}
