import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import { Context as OffersContext } from '../../context/OffersContext';
import { Context as UserContext } from '../../context/UserContext';
import { Context as OfferContext } from '../../context/OfferContext';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 750,
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	title: {
		marginTop: theme.spacing(4),
	},
	topButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(4),
	},
	modalButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(3),
	},
}));

export default function OffersPage({ onRender }) {
	const classes = useStyles();
	const history = useHistory();

	// STATE
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [offerToDelete, setOfferToDelete] = useState(null);

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { offers, loading: offersLoading, error: offersError },
		loadOffers,
	} = useContext(OffersContext);

	const {
		state: { offer, error: offerError },
		createOffer,
		deleteOffer,
	} = useContext(OfferContext);

	// EFFECTS
	useEffect(() => {
		if (user || offer) {
			loadOffers();
		}

		onRender('Offers');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, offer]);

	function handleDeleteOffer(id) {
		setDeleteModalOpen(true);
		setOfferToDelete(id);
	}

	async function handleCreateOffer() {
		const newOffer = await createOffer();
		history.push(`/offers/${newOffer._id}`);
	}

	if (offersError || offerError) {
		return <Error error={offersError || offerError} />;
	}

	if (offersLoading) {
		return <Loading />;
	}

	if (!offers.length) {
		return null;
	}

	return (
		<>
			<Modal open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
				<Box>
					<Typography variant='h6' gutterBottom>
						Are you sure you want to delete this offer?
					</Typography>
				</Box>
				<Box className={classes.modalButtons}>
					<Button
						variant='contained'
						color='primary'
						style={{ marginRight: '10px' }}
						onClick={async () => {
							setDeleteModalOpen(false);
						}}
					>
						No
					</Button>
					<Button
						variant='contained'
						color='secondary'
						onClick={async () => {
							await deleteOffer(offerToDelete);
							setDeleteModalOpen(false);
						}}
					>
						Yes
					</Button>
				</Box>
			</Modal>
			<Container className={classes.container}>
				<Box className={classes.topButtons}>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						onClick={() => handleCreateOffer()}
					>
						Add
					</Button>
				</Box>
				<TableContainer component={Paper} className={classes.paper}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Id</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Sticky campaign id</TableCell>
								<TableCell>Everflow offer id</TableCell>
								<TableCell>GTM id</TableCell>
								<TableCell>GA id</TableCell>
								<TableCell align='right'>Delete</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{offers.map((row) => (
								<TableRow key={row.name} hover>
									<TableCell component='th' scope='row'>
										<Link
											component='button'
											variant='body2'
											onClick={() => {
												history.push(`/offers/${row._id}`);
											}}
										>
											{row._id}
										</Link>
									</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.campaignId}</TableCell>
									<TableCell>{row.everflowOfferId}</TableCell>
									<TableCell>{row.gtmId}</TableCell>
									<TableCell>{row.gaId}</TableCell>
									<TableCell align='right'>
										<IconButton
											color='secondary'
											aria-label='Delete offer'
											component='span'
											className={classes.fbPixelDelete}
											onClick={() => handleDeleteOffer(row._id)}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
		</>
	);
}
