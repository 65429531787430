import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ImageInput from '../../components/ImageInput/ImageInput';
import ChipsList from '../../components/ChipsList/ChipsList';
import Error from '../../components/Error/Error';
import { Context as UserContext } from '../../context/UserContext';
import { Context as CrossSellContext } from '../../context/CrossSellContext';
import { Context as OffersContext } from '../../context/OffersContext';

import { Paper, Typography } from '@material-ui/core';
import MultiSelect from '../../components/MultiSelect/MultiSelect';

const useStyles = makeStyles((theme) => ({
	paper: {
		width: '100%',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	title: {
		marginBottom: theme.spacing(2),
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '26px',
	},
	container: {
		marginTop: theme.spacing(3),
	},
	progress: {
		marginTop: theme.spacing(2),
	},
	textField: {
		marginBottom: theme.spacing(3),
	},
	select: {
		marginBottom: theme.spacing(3),
	},
	rowContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		'& $textField': {
			marginRight: theme.spacing(3),
			'&:last-child': {
				marginRight: '0',
			},
		},
	},
	benefit: {
		display: 'flex',
		width: '100%',
		marginBottom: theme.spacing(3),
		borderBottom: '1px solid #eee',
		paddingBottom: theme.spacing(2),
	},
	benefitLeft: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	benefitIcons: {
		display: 'flex',
		justifyContent: 'center',
	},
	benefitIcon: {
		alignSelf: 'center',
		marginLeft: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			marginBottom: theme.spacing(2),
		},
	},
}));

export default function CrossSellPage({ onRender }) {
	// VARS
	const classes = useStyles();
	const { id } = useParams();

	// STATE
	const [discount, setDiscount] = useState('');
	const [stock, setStock] = useState('');
	const [name, setName] = useState('');
	const [productId, setProductId] = useState('');
	const [msrp, setMsrp] = useState('');
	const [price, setPrice] = useState('');
	const [imageSrc, setImageSrc] = useState('');
	const [variants, setVariants] = useState('');

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { offers, error: offersError },
		loadOffers,
	} = useContext(OffersContext);

	const {
		state: { crossSell, loading: crossSellLoading, error: crossSellError },
		loadCrossSell,
		updateCrossSell,
	} = useContext(CrossSellContext);

	// EFFECTS
	useEffect(() => {
		if (user) {
			loadCrossSell(id);
			loadOffers();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (crossSell) {
			onRender(`Cross-sell - ${crossSell.name}`);
			// Set values after crossSell is loaded
			setDiscount(crossSell.discount);
			setName(crossSell.name);
			setStock(crossSell.stock);
			setProductId(crossSell.productId);
			setMsrp(crossSell.msrp);
			setPrice(crossSell.price);
			setImageSrc(crossSell.imageSrc);
			setVariants(crossSell.variants);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crossSell]);

	if (crossSellError || offersError) {
		return <Error error={crossSellError || offersError} />;
	}

	if (!crossSell) {
		return null;
	}

	function handleCrossSellUpdate(update) {
		updateCrossSell(id, update);
	}

	function renderVariants() {
		function handleUpdateVariants(e, index) {
			const newVariants = [...variants];
			newVariants[index][e.target.name] = e.target.value;
			setVariants(newVariants);
		}

		function handleAddVariants() {
			handleCrossSellUpdate({
				variants: [
					...variants,
					{
						name: '',
						value: '',
						productId: '',
						productName: '',
						productImg: '',
					},
				],
			});
		}

		function handleDeleteVariants(productId) {
			handleCrossSellUpdate({
				variants: variants.filter((item) => item.productId !== productId),
			});
		}

		if (!variants.length) {
			return (
				<>
					<Typography className={classes.title} variant='subtitle1' gutterBottom>
						Variants
					</Typography>
					<IconButton
						size='small'
						color='primary'
						aria-label='Add'
						component='span'
						onClick={() => handleAddVariants()}
						className={classes.benefitIcon}
					>
						<AddIcon />
					</IconButton>
				</>
			);
		}

		return (
			<>
				<Typography className={classes.title} variant='subtitle1' gutterBottom>
					Variants
				</Typography>
				{variants.map(({ name, value, productId, productName, productImg }, index) => (
					<Box className={classes.benefit} key={index}>
						<Box className={classes.benefitLeft}>
							<TextField
								name='name'
								label='Variant name'
								value={name}
								helperText='color, size...'
								onChange={(e) => handleUpdateVariants(e, index)}
								className={classes.textField}
								onBlur={() =>
									handleCrossSellUpdate({
										variants,
									})
								}
							/>
							<TextField
								name='value'
								label='Variant value'
								value={value}
								helperText='black, big...'
								onChange={(e) => handleUpdateVariants(e, index)}
								className={classes.textField}
								onBlur={() =>
									handleCrossSellUpdate({
										variants,
									})
								}
							/>
							<TextField
								name='productId'
								label='Sticky Product ID'
								value={productId}
								className={classes.textField}
								onChange={(e) => handleUpdateVariants(e, index)}
								onBlur={() =>
									handleCrossSellUpdate({
										variants,
									})
								}
							/>
							<TextField
								name='productName'
								label='Product name'
								value={productName}
								className={classes.textField}
								onChange={(e) => handleUpdateVariants(e, index)}
								onBlur={() =>
									handleCrossSellUpdate({
										variants,
									})
								}
							/>
							<ImageInput
								name='productImg'
								label='Product image'
								value={productImg}
								className={classes.textField}
								onChange={(e) => handleUpdateVariants(e, index)}
								onBlur={() =>
									handleCrossSellUpdate({
										variants,
									})
								}
							/>
						</Box>
						<Box className={classes.benefitIcons}>
							<IconButton
								color='secondary'
								aria-label='delete variants'
								component='span'
								size='small'
								className={classes.benefitIcon}
								onClick={() => handleDeleteVariants(productId)}
							>
								<DeleteIcon />
							</IconButton>

							<IconButton
								size='small'
								color='primary'
								aria-label='Add'
								component='span'
								onClick={() => handleAddVariants()}
								className={classes.benefitIcon}
							>
								<AddIcon />
							</IconButton>
						</Box>
					</Box>
				))}
			</>
		);
	}

	function renderOffersSelect() {
		const options = offers.map((item) => ({
			id: item._id,
			name: item.name,
		}));

		return (
			<Box className={classes.select}>
				<MultiSelect
					label='Preselected for Offers:'
					optionLabel='name'
					options={options}
					value={crossSell.preselectedOffers}
					className={classes.select}
					onChange={(values) =>
						handleCrossSellUpdate({
							preselectedOffers: values,
						})
					}
				/>
			</Box>
		);
	}

	return (
		<Container maxWidth='lg' className={classes.container}>
			{crossSellLoading && <LinearProgress className={classes.progress} />}
			<Paper className={classes.paper}>
				<TextField
					label='Id'
					value={crossSell._id}
					fullWidth
					InputProps={{
						readOnly: true,
					}}
					className={classes.textField}
				/>
				<TextField
					label='Name'
					value={name}
					onChange={(e) => setName(e.target.value)}
					onBlur={() =>
						handleCrossSellUpdate({
							name,
						})
					}
					fullWidth
					className={classes.textField}
				/>

				<TextField
					label='Sticky product id'
					value={productId}
					helperText='Will be ignored if this product has variants.'
					onChange={(e) => setProductId(e.target.value)}
					onBlur={() =>
						handleCrossSellUpdate({
							productId,
						})
					}
					fullWidth
					className={classes.textField}
				/>
				<TextField
					label='Price'
					value={price}
					helperText='Example: $9.90'
					onChange={(e) => setPrice(e.target.value)}
					onBlur={() =>
						handleCrossSellUpdate({
							price,
						})
					}
					fullWidth
					className={classes.textField}
				/>
				<TextField
					label='MSRP'
					helperText='Example: $19.90'
					value={msrp}
					onChange={(e) => setMsrp(e.target.value)}
					onBlur={() =>
						handleCrossSellUpdate({
							msrp,
						})
					}
					fullWidth
					className={classes.textField}
				/>

				<TextField
					label='Discount'
					value={discount}
					onChange={(e) => setDiscount(e.target.value)}
					helperText='Example: 50%'
					onBlur={() =>
						handleCrossSellUpdate({
							discount,
						})
					}
					fullWidth
					className={classes.textField}
				/>
				<TextField
					label='Stock'
					value={stock}
					onChange={(e) => setStock(e.target.value)}
					helperText='Example: only 9 left'
					onBlur={() =>
						handleCrossSellUpdate({
							stock,
						})
					}
					fullWidth
					className={classes.textField}
				/>

				{renderOffersSelect()}
				<ChipsList
					items={crossSell.benefits}
					onUpdate={(newItems) => handleCrossSellUpdate({ benefits: newItems })}
					title='Benefits'
				/>

				<ImageInput
					label='Image source url'
					helperText='Will be ignored if this product has variants.'
					value={imageSrc}
					onChange={(e) => setImageSrc(e.target.value)}
					onBlur={(e) =>
						handleCrossSellUpdate({
							imageSrc,
						})
					}
				/>
				{renderVariants()}
			</Paper>
		</Container>
	);
}

CrossSellPage.propTypes = {
	onRender: PropTypes.func,
};
